import React, { Component } from "react";
import Image24 from "../images/faq1.png";
import "../styles/newhp.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
// import OwlCarousel from "react-owl-carousel";
import f1 from "../images/f1.png";
import f2 from "../images/f2.png";
import f3 from "../images/f3.png";
import f4 from "../images/f4.png";
import newimg from "../images/Newimg.jpeg";
import newphoto from "../images/newimg3.png";
import lab from "../images/lab2.png";
import vir from "../images/vir.png";
import ste from "../images/med.png";
import lab1 from "../images/science.png";
import educ from "../images/educ.png";
import f5 from "../images/f5.png";
import eng from "../images/eng.png";
import p1 from "../images/p1.jpeg";
import p2 from "../images/p2.jpeg";
import p3 from "../images/p3.jpeg";
import p4 from "../images/p4.jpeg";
import p5 from "../images/p5.jpeg";
import p6 from "../images/p6.jpeg";

import question from "../images/Questions.png";
import Image83 from "../images/2204.i201.043.F.m004.c9.walking people isometric.jpg";
import img from "../images/study.jpg";
import LightSpeed from "react-reveal/LightSpeed";
import iot from "../images/iot1.svg";
import blockchain1 from "../images/blockchain1.svg";
import ai1 from "../images/AI1.svg";
import ml1 from "../images/ML1.svg";
import chatbot from "../images/Chatbots.svg";
import vr1 from "../images/vr1.svg";
import ar1 from "../images/ar1.svg";
import { Icon } from "@iconify/react";
import ace1 from "../images/ace1.png";
import grp from "../images/grp.jpg";
import numone from "../images/num1.png";
import numtwo from "../images/num2.png";
import numthree from "../images/num3.png";
import company from "../images/company1.png";
import message from "../images/chat.png";
import group from "../images/group.png";
import single from "../images/study1.png";
import Footer from "./footer";
import "../styles/navbar.css";
import "../styles/hp.css";
import About from "../images/About .jpg";
import Navbar from "./Navbar.js";
import NewNavbar from "./Newnav";
import edu from "../images/edu.jpg";
// import mobile from "../images/mobile1.png";
import mobile from "../images/ar1.jpg";
import { useState } from "react";

// const [isCollapsed1, setIsCollapsed1] = useState(false);

// const toggle1 = () => {
//   setIsCollapsed1(!isCollapsed1);
// };
// const [isCollapsed2, setIsCollapsed2] = useState(false);

// const toggle2 = () => {
//   setIsCollapsed2(!isCollapsed2);
// };
// const [isCollapsed3, setIsCollapsed3] = useState(false);

// const toggle3 = () => {
//   setIsCollapsed3(!isCollapsed3);
// };
// const [isCollapsed4, setIsCollapsed4] = useState(false);

// const toggle4 = () => {
//   setIsCollapsed4(!isCollapsed4);
// };
// const [isCollapsed5, setIsCollapsed5] = useState(false);

// const toggle5 = () => {
//   setIsCollapsed5(!isCollapsed5);
// };
export default class Academy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      activeButton: 1,
      qfa5: false,
      showPopup: false,
      name: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",

      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,
      images: [p1, p2, p3, p4, p5, p6],
      servicesList: [
        "Research Training",
        "Industrial Training Programme",
        "On-Campus Training Programme",
        "Hospital Based Training Programme",
        " Laboratory based Training Programme",
        " Research Paper Publication Support - Scopus, SCI, SCI-E, WoS, ABDC, and UGC Listed Journals",
        " Indian & International Patent Support upto Grant (UK, Vietnam, Canadian, German, South African",
        " Copyright Grant Support",
        " Book Publication support",
        " Technical Training Certification Courses",
        " On-Board Training Programme",
        " Pre-Final Year and Final Year Projects",
        " Govt. Funded Project Support",
        " Expert Training Programme",
        " Award Programme and Conclave Support",
        " Book of Records Support",
        " Faculty Development Programme",
        " Workshop and Seminars",
        " Credit Courses and Curriculum Designing",
        " Entrepreneurship Cell Development",
        " Paper Writing & Publication Services",
        " NAAC, NIRF, AIIRA, NBA Support",
        " Placement Support",
        " Consultancy Linkage",
      ],
    };
    this.timer = null;
  }
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };
  showContent = (buttonNumber) => {
    this.setState({ activeButton: buttonNumber });
  };
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  render() {
    const { activeButton } = this.state;
    const { showPopup, name, mobileNumber, email } = this.state;
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <div>
            {" "}
            <Navbar />
          </div>
          <br />
          <br />{" "}
          <div className="content_acc">
            <Container>
              <Row
                style={{
                  margin: "0px",
                }}
              >
                <Col md={6} xs={12} lg={6}>
                  <Fade bottom>
                    <div className="quote-align">
                      <h1 className="quotenew">
                        Master In-Demand Tech & Data Skills at KambGeeks
                      </h1>
                      <br />
                      <h6
                        className="quote-desc"
                        style={{ color: "white", paddingBottom: "5%" }}
                      >
                        We offer industry-focused courses led by experts to help
                        you become job-ready in data science, programming, and
                        more.
                        {/* 100+ our learners now working in top
                      product base companies. */}
                      </h6>
                      <br />
                      <a
                        data-text="Explore Programs"
                        className="quo"
                        style={{ alignSelf: "center" }}
                        href="#ProgramCourses"
                      >
                       Explore Programs
                      </a>
                    </div>
                  </Fade>
                </Col>
                <Col md={6} xs={12} lg={6}>
                  <Bounce>
                    <img src={newphoto} className="pic_accofnew" />
                  </Bounce>
                </Col>
              </Row>
            </Container>
          </div>
          <br />
          <br />
          <section className="hero-section">
            <br />
            <br />
            <div className="container">
              <div className="text-center">
                <button className="btn-type-div-border-cst">
                  Build Real-World Skills, Network with Peers, and Unlock Career
                  Opportunities.
                </button>
              </div>
              <div className="d-flex justify-content-center text-center">
                <div className="col-sm-6 col-12">
                  <h1>
                    Building Largest Tech Community Platform
                    {/* <span className="cst-secondary-color"> Generative AI</span> */}
                  </h1>
                </div>
              </div>

              {/* <h5 className="text-center mt-4">
                Go Beyond Networking: Build meaningful relationships within the
                Kamb Geeks community
              </h5> */}

              {/* <h5 className="text-center mt-4">
                Join our dynamic WhatsApp community and connect with motivated
                peers. Learn from out-of-the-box thinkers and discover new
                approaches to problem-solving.
              </h5> */}
              <h5 className="text-center mt-4">
                Build meaningful relationships within the Kamb Geeks community.
              </h5>
              {/* <h5 className="text-center mt-4">
                Supercharge Your Learning with Kamb Geeks
              </h5> */}
              <h5 className="text-center mt-4">
                Participate in live Q&As, engaging debates, hackathons, and
                exciting contests to solidify your learning.
              </h5>
              <div className="text-center mt-4 mb-3">
                <a className="btn get-started-free-btn" href="/Register">
                  Get Early Access
                </a>
              </div>
              <section className="how-it-works-section pb-5">
                <div className="container">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="apply-company-and-developers text-center">
                          <br />
                          <a
                            class="btn btn-type-div-border-cst"
                            href="ContactUs"
                          >
                            {/* Save Large on Community */}
                            Learn What Actually Need
                          </a>
                          <br />
                          <h1>
                            For
                            <span class="cst-secondary-color"> Learners</span>
                          </h1>
                          <p>
                            Supercharge Your Learning with Kamb Geeks
                            <br />
                            Build Real-World Skills, Network with Peers,
                            <br />
                            and Unlock Career Opportunities.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="#ProgramCourses"
                            style={{ textDecoration: "none" }}
                          >
                            Explore Programs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="apply-company-and-developers text-center">
                          <br />
                          <a
                            class="btn btn-type-div-border-cst"
                            href="ContactUs"
                          >
                            Connect with Like-Minded Peers
                          </a>
                          <br />
                          <h1>
                            For
                            <span class="cst-secondary-color"> Company</span>
                          </h1>
                          <p>
                            Hire developers locally, offshore, or
                            <br />
                            nearshore, all vetted by AI and
                            <br />
                            handpicked by humans.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="ContactUs"
                            style={{ textDecoration: "none" }}
                          >
                            Hire Software Engineers
                          </a>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="border-line-col-cst text-center">
                          <br /> <br />
                          <button class="btn btn-type-div-border-cst">
                            You deserve a better pay.
                          </button>
                          <h1>
                            For
                            <span class="cst-secondary-color"> Talents</span>
                          </h1>
                          <p>
                            Traditional job portals are dead.
                            <br />
                            Let KambGeeks match you with high-paying
                            <br />
                            full time tech jobs.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="ContactUs"
                            style={{ textDecoration: "none" }}
                          >
                            Apply for jobs
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="text-center">
                <span className="text-center mt-2 pb-5">
                  Save up to <b>70%</b> On hiring
                </span>
              </div> */}

              {/* <div className="bad-hire-costly-section"></div> */}
            </div>
            <br />

            <br />
          </section>
          <div className="seconda">
            <br />
            <br />
            <br />
            <br />
            <h1
              className="h-heading"
              style={{
                color: "white",
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              Our Achievements
            </h1>
            <br />
            <br />
            <div className="academy-container">
              <div className="academy-1">
                <Row>
                  <Col>
                    <p className="academy-head highlight1">1K+</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Total Students</p>
                  </Col>
                </Row>
              </div>
              <div className="academy-2">
                <Row>
                  <Col>
                    <p className="academy-head highlight2">10+</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Programs</p>
                  </Col>
                </Row>
              </div>
              <div className="academy-3">
                <Row>
                  <Col>
                    <p className="academy-head highlight3">8.1/10</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Average Rating</p>
                  </Col>
                </Row>
              </div>
              <div className="academy-4">
                <Row>
                  <Col>
                    <p className="academy-head highlight4">70+</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Institutions</p>
                  </Col>
                </Row>
              </div>
            </div>
            <br />
            <div className="academy-container1">
              <div className="academy-1">
                <Row>
                  <Col>
                    <p className="academy-head1 highlight5">200+</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Hiring Patners</p>
                  </Col>
                </Row>
              </div>
              <div className="academy-2">
                <Row>
                  <Col>
                    <p className="academy-head1 highlight6">10 LPA</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Average CTC</p>
                  </Col>
                </Row>
              </div>
              <div className="academy-3">
                <Row>
                  <Col>
                    <p className="academy-head1 highlight7">32-35 LPA</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="academy-content">Highest CTC</p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <br />
          <section className="hero-section">
            <br />
            <br />
            <div className="container">
              <section className="how-it-works-section pb-5">
                <div className="container">
                  <div style={{ textAlign: "center" }}>
                    <p className="hereset">Here's what sets us apart:</p>
                  </div>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <p className="hereset">
                        Ready to join the KambGeeks revolution?
                      </p>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="apply-company-and-developers text-center">
                          <a
                            class="btn btn-type-div-border-cst"
                            href="ContactUs"
                          >
                            {/* Save Large on Community */}
                            Learn What Actually Need
                          </a>
                          <br />
                          <h1>
                            Personalized
                            <span class="cst-secondary-color"> Mentorship</span>
                          </h1>
                          <p>
                            Forget one-size-fits-all learning.
                            <br /> Our students benefit from dedicated mentors
                            <br /> who guide them through their journey,
                            <br />
                            ensuring they grasp concepts and build
                            <br />
                            real-world skills. Which will enable them to build
                            <br />
                            software products from scratch.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="#ProgramCourses"
                            style={{ textDecoration: "none" }}
                          >
                            Join Community
                          </a>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="apply-company-and-developers text-center">
                          <a
                            class="btn btn-type-div-border-cst"
                            href="ContactUs"
                          >
                            Save a Month on hiring!
                          </a>
                          <br />
                          <h1>
                            Thriving KambGeeks
                            <span class="cst-secondary-color"> Community</span>
                          </h1>
                          <p>
                            You're not alone! Our vibrant community
                            <br /> connects you with like-minded individuals.
                            <br />
                            Collaborate, network, learn and clear doubts from
                            <br />
                            each other, hackthon and tech events
                            <br />
                            ,fostering a supportive and enriching learning
                            environment.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="ContactUs"
                            style={{ textDecoration: "none" }}
                          >
                            Hire Software Engineers
                          </a>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="border-line-col-cst text-center">
                          <br />
                          <button class="btn btn-type-div-border-cst">
                            You deserve a better pay.
                          </button>
                          <h1>
                            Fast-Track
                            <span class="cst-secondary-color">
                              {" "}
                              Your Career
                            </span>
                          </h1>
                          <p>
                            Don't just learn, land your dream job
                            <br />
                            on a Top Tech Company! Upon Graduation,
                            <br />
                            KambGeeks students gain access to
                            <br /> our exclusive job portal,
                            <br />
                            brimming with exciting
                            <br /> opportunities.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="Register"
                            style={{ textDecoration: "none" }}
                          >
                            Apply for jobs
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* <div class="container">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="apply-company-and-developers text-center">
                          <br />
                          <a
                            class="btn btn-type-div-border-cst"
                            href="ContactUs"
                          >
                            Save a Month on hiring!
                          </a>
                          <br />
                          <h1>
                            For
                            <span class="cst-secondary-color"> Learners</span>
                          </h1>
                          <p>
                            Explore our in-demand tech program and
                            <br />
                            discover how we can help you
                            <br />
                            achieve your tech career goals.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="ContactUs"
                            style={{ textDecoration: "none" }}
                          >
                            Hire Software Engineers
                          </a>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="border-line-col-cst text-center">
                          <br /> <br />
                          <button class="btn btn-type-div-border-cst">
                            You deserve a better pay.
                          </button>
                          <h1>
                            For
                            <span class="cst-secondary-color"> Business</span>
                          </h1>
                          <p>
                            Looking for top-tier tech talent?
                            <br />
                            Our pool of highly trained and vetted graduates
                            <br />
                            is ready to hit the ground running.
                          </p>
                          <a
                            class="get-started-free-btn"
                            href="ContactUs"
                            style={{ textDecoration: "none" }}
                          >
                            Apply for jobs
                          </a>
                        </div>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <br />
                        <br />
                        <p className="hereset">
                          Let's build the future together!
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>

              {/* <div className="text-center">
                <span className="text-center mt-2 pb-5">
                  Save up to <b>70%</b> On hiring
                </span>
              </div> */}

              {/* <div className="bad-hire-costly-section"></div> */}
            </div>
            <br />
            <br />
            <br />
            <br />
          </section>
          <br />
          <div id="special">
            <div>
              <h1
                className="h-heading"
                style={{
                  color: "white",
                  fontWeight: "900",
                  textAlign: "center",
                  fontFamily: "Prompt",
                }}
              >
                We specialize in?
              </h1>
              <br />
              <LightSpeed left>
                <div className="splbox">
                  <Row style={{ margin: "0px" }}>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img src={iot} className="specialimg" alt="IoT" />
                        <p style={{ fontFamily: "Prompt" }}>IoT</p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={blockchain1}
                          className="specialimg"
                          alt="Data Engineering"
                        />
                        <p style={{ fontFamily: "Prompt" }}>Data Engineering</p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={ai1}
                          className="specialimg"
                          alt="Artificial Intelligence"
                        />
                        <p style={{ fontFamily: "Prompt" }}>
                          Artificial Intelligence
                        </p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={ml1}
                          className="specialimg"
                          alt="Data Science"
                        />
                        <p style={{ fontFamily: "Prompt" }}>Data Science</p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img src={vr1} className="specialimg" alt="DEVOPS" />
                        <p style={{ fontFamily: "Prompt" }}>DEVOPS</p>
                      </div>
                    </Col>

                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={ar1}
                          className="specialimg"
                          alt="Mobile Development"
                        />
                        <p style={{ fontFamily: "Prompt" }}>
                          Mobile Development
                        </p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={chatbot}
                          className="specialimg"
                          alt="Software Development"
                        />
                        <p style={{ fontFamily: "Prompt" }}>
                          Software Development
                        </p>
                      </div>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="6">
                      <div className="splimgbox">
                        <img
                          src={mobile}
                          className="specialimg"
                          alt="AR/VR Courses"
                        />
                        <p style={{ fontFamily: "Prompt" }}>AR/VR Courses</p>
                      </div>
                    </Col>
                    {/* <Col md="2" lg="2" sm="6" xs="6">
                    <div className="splimgbox">
                      <img
                        src={lab1}
                        className="specialimg"
                        alt="Live Science"
                      />
                      <p>Life Science</p>
                    </div>
                  </Col> */}
                    {/* <Col md="3" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img
                        src={educ}
                        className="specialimg"
                        alt="\Engineering"
                      />
                      <p>Engineering Departments</p>
                    </div>
                  </Col> */}
                    {/* <Col md="2" lg="2" sm="6" xs="6">
                    <div className="splimgbox">
                      <img
                        src={vir}
                        className="specialimg"
                        alt="\Arts and Science"
                      />
                      <p>Arts & Science</p>
                    </div>
                  </Col> */}
                    {/* <Col md="2" lg="2" sm="6" xs="6">
                    <div className="splimgbox">
                      <img
                        src={ste}
                        className="specialimg"
                        alt="\Medical Science"
                      />
                      <p>Medical Science</p>
                    </div>
                  </Col> */}
                  </Row>
                </div>
              </LightSpeed>
            </div>
          </div>
          <br />
          <div className="container mt-5">
            <h2
              // className="text-center mb-4"
              className="h-heading"
              id="ProgramCourses"
              style={{
                color: "white",
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              Program
            </h2>
            <br />
            <div>
              <div className="button-container">
                <button
                  className={
                    "button1 " + (activeButton === 1 ? "active-button" : "")
                  }
                  onClick={() => this.showContent(1)}
                >
                  DEVOPS
                </button>
                <button
                  className={
                    "button1 " + (activeButton === 2 ? "active-button" : "")
                  }
                  onClick={() => this.showContent(2)}
                >
                  Artificial Intelligence
                </button>
                <button
                  className={
                    "button1 " + (activeButton === 3 ? "active-button" : "")
                  }
                  onClick={() => this.showContent(3)}
                >
                  Data Science
                </button>
                <button
                  className={
                    "button1 " + (activeButton === 4 ? "active-button" : "")
                  }
                  onClick={() => this.showContent(4)}
                >
                  IoT
                </button>
                <button
                  className={
                    "button1 " + (activeButton === 5 ? "active-button" : "")
                  }
                  onClick={() => this.showContent(5)}
                >
                  Full Stack
                </button>
                <div
                  className="arrow"
                  onClick={() => this.showContent(activeButton + 1)}
                ></div>
              </div>

              <div className="content1">
                {activeButton === 1 && (
                  <div>
                    <div className="row g-5 mt-3 justify-content-center">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>DevOps</li>
                            <li>1 Hour</li>
                          </ul>
                          <h3>Introduction and Workshop Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/devops">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card1">
                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>DevOps</li> <li>4 Weeks</li>
                          </ul>
                          <h3>DevOps Internship and Certification Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/devops4week">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card2">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>DevOps</li>
                            <li>24 Weeks</li>
                            <li>1 to 1 Mentorship</li>
                          </ul>
                          <h3>Extensive DevOps Internship Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/devops3month">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                )}
                {activeButton === 2 && (
                  <div>
                    {" "}
                    <div className="row g-5 mt-3 justify-content-center">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>AI</li>
                            <li>1 Hour</li>
                          </ul>
                          <h3>Introduction and Workshop Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/AI1hour">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card1">
                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>AI</li> <li>4 Weeks</li>
                          </ul>
                          <h3>Artificial Intelligence Internship Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/AI4week">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card2">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>AI</li>
                            <li>24 Weeks</li>
                            <li>1 to 1 Mentorship</li>
                          </ul>
                          <h3>Extensive AI Internship Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/AI">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                )}
                {activeButton === 3 && (
                  <div>
                    {" "}
                    <div className="row g-5 mt-3 justify-content-center">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data Science</li>
                            <li>1 Hour</li>
                            {/* <li>1 to 1 Mentorship</li> */}
                          </ul>
                          <h3>Introduction and Workshop Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/datascience1hour">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card1">
                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data Science</li> <li>4 Weeks</li>
                          </ul>
                          <h3>
                            Data Science Internship and Certification Program
                          </h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/datascience4week">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card2">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data Science</li>
                            <li>24 Weeks</li>
                            <li>1 to 1 Mentorship</li>
                          </ul>
                          <h3>Extensive Data Science Internship Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/datascience3month">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                )}
                {activeButton === 4 && (
                  <div>
                    {" "}
                    <div>
                      {" "}
                      <div className="row g-5 mt-3 justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                          <div className="testi-card">
                            <div className="testi-img position-relative"></div>

                            <ul
                              className="testi-list my-3"
                              style={{ paddingLeft: "0em" }}
                            >
                              <li>IoT</li>
                              <li>1 Hour</li>
                              {/* <li>1 to 1 Mentorship</li> */}
                            </ul>
                            <h3>Introduction and Workshop Program</h3>
                            <h2>
                              {" "}
                              Multiple Mentors from brands like Amazon, Google &
                              more
                            </h2>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <a className="applynow" href="/Iot1hour">
                                Apply Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                          <div className="testi-card1">
                            <ul
                              className="testi-list my-3"
                              style={{ paddingLeft: "0em" }}
                            >
                              <li>IoT</li> <li>4 Weeks</li>
                            </ul>
                            <h3>IoT Internship Program and Certification</h3>
                            <h2>
                              {" "}
                              Multiple Mentors from brands like Amazon, Google &
                              more
                            </h2>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <a className="applynow" href="/Iot4week">
                                Apply Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                          <div className="testi-card2">
                            <div className="testi-img position-relative"></div>

                            <ul
                              className="testi-list my-3"
                              style={{ paddingLeft: "0em" }}
                            >
                              <li>IoT</li>
                              <li>24 Weeks</li>
                              <li>1 to 1 Mentorship</li>
                            </ul>
                            <h3>Extensive IoT Internship Program</h3>
                            <h2>
                              {" "}
                              Multiple Mentors from brands like Amazon, Google &
                              more
                            </h2>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <a className="applynow" href="/Iot3month">
                                Apply Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                )}
                {activeButton === 5 && (
                  <div>
                    {" "}
                    <div className="row g-5 mt-3 justify-content-center">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>FullStack</li>
                            <li>1 Hour</li>
                          </ul>
                          <h3>Introduction and Workshop Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/fullstack1hour">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card1">
                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>FullStack</li> <li>4 Weeks</li>
                          </ul>
                          <h3>Full Stack Program for Internship</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/fullstack4week">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card2">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>FullStack</li>
                            <li>24 Weeks</li>
                            <li>1 to 1 Mentorship</li>
                          </ul>
                          <h3>Extensive Full Stack Internship Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/fullstack">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                )}
                {activeButton === 6 && (
                  <div>
                    {" "}
                    <div className="row g-5 mt-3 justify-content-center">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data</li>
                            <li>1 Hour</li>
                            <li>1 to 1 Mentorship</li>
                          </ul>
                          <h3>Full Stack Program</h3>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/Register">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card1">
                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data</li> <li>4 Weeks</li>
                          </ul>
                          <h3>Full Stack Program</h3>
                          <h2>
                            {" "}
                            Multiple Mentors from brands like Amazon, Google &
                            more
                          </h2>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/Register">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="testi-card2">
                          <div className="testi-img position-relative"></div>

                          <ul
                            className="testi-list my-3"
                            style={{ paddingLeft: "0em" }}
                          >
                            <li>Data</li>
                            <li>24 Weeks</li>
                          </ul>
                          <h3>Full Stack Program</h3>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <a className="applynow" href="/Register">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Fade left>
            <div className="row">
              {this.state.servicesList.map((service, index) => (
                <div key={index} className="col-md-4 mb-4">
                  <div className="card d-flex h-100  hover-shadow">
                    <div className="card-body d-flex flex-column">
                     
                      <p
                        className="card-text flex-fill"
                        style={{ fontSize: "20px" }}
                      >
                        {service}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Fade> */}
          </div>
          <br /> <br />
          <div>
            <h1
              className="h-heading"
              style={{
                color: "white",
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              Why Kambgeeks?
            </h1>
            <br />
            <div className="ace-container">
              <div>
                <Row>
                  <Col>
                    <Icon
                      className="ace-icon"
                      icon="mdi:marker-tick"
                      color="#1d60e3"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="ae-head2" style={{ fontFamily: "Prompt" }}>
                      Industry-Driven Curriculum
                      <br />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="h-content" style={{ fontFamily: "Prompt" }}>
                      Master in-demand skills with coursework developed
                      alongside industry leaders and academics. Gain the
                      knowledge and practical experience employers seek.
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <Icon
                      className="ace-icon"
                      icon="clarity:assign-user-solid"
                      color="#1d60e3"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="ae-head2" style={{ fontFamily: "Prompt" }}>
                      Expert Instructors, Cutting-Edge Knowledge
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="h-content" style={{ fontFamily: "Prompt" }}>
                      Learn from industry professionals actively working in the
                      field. Gain insights from leading practitioners who bring
                      real-world best practices and case studies directly to
                      your learning experience
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <Icon
                      className="ace-icon"
                      icon="ic:outline-code"
                      color="#1d60e3"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="ae-head2" style={{ fontFamily: "Prompt" }}>
                      Hands-on Learning with Real-World Projects
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="h-content" style={{ fontFamily: "Prompt" }}>
                      Apply your knowledge to solve real-world problems. Our
                      capstone projects utilize authentic data sets and virtual
                      labs for immersive, hands-on learning
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <Icon
                      className="ace-icon"
                      icon="mdi:alarm-clock-check"
                      color="#1d60e3"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="ae-head2" style={{ fontFamily: "Prompt" }}>
                      24/7 Support for Continuous Learning
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="h-content" style={{ fontFamily: "Prompt" }}>
                      Never get stuck. Our dedicated mentors and supportive
                      community of peers are here to answer your questions and
                      guide you on your learning journey.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Our Learners work at some of the best companies in the world
          
          </h1>
          <br />
          <Fade>
            <Col md="2" />
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <img src={company} style={{ width: "80%" }} />
            </Col>
            <Col md="3" />
          </Fade>
        </div> */}
          <div
            style={{
              backgroundImage:
                "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
              color: "white",
            }}
          >
            <br />

            <div className="container">
              <div class="row justify-content-center justify-content-lg-start">
                <br />

                <div class="col-8 mx-auto">
                  <br />
                  <br />
                  <br />

                  <br />

                  <h2
                    class="pathnew mb-2 mb-lg-4"
                    style={{ fontFamily: "Prompt" }}
                  >
                    Unsure of which tech course will suit you the best?
                  </h2>
                  <p className="pathnew1" style={{ fontFamily: "Prompt" }}>
                    Worry not, help us understand you better by taking this 30
                    second Kambquiz and we will recommend a course which will be
                    the perfect fit for you!
                  </p>
                  <br />
                  <br />
                  <a
                    className="salebtnnew"
                    href="Register"
                    style={{ fontFamily: "Prompt" }}
                  >
                    I want to take KambQuiz!
                  </a>
                  <br />

                  <br />
                </div>
              </div>
            </div>

            <br />
          </div>
          <br />
          {/* <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "900",
              textAlign: "center",
              fontFamily: "Prompt",
            }}
          >
            What makes us different?
          </h1>
          <br />
          <div>
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="0" xs="0">
                <Bounce>
                  <img
                    src={grp}
                    alt="global"
                    width="100%"
                    height="100%"
                    className="pic_acc1"
                  />
                </Bounce>
              </Col>
              <Col md="6" lg="6" sm="12" xs="12">
                <Fade right>
                  <div className="ex-container">
                    <img src={message} alt="message" width="60%" height="75%" />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Interact with the best
                      </span>
                      <br />
                      Go beyond pre-recorded courses go swith handsonlearning
                      and get doubts cleared personally
                    </span>
                  </div>

                  <hr />
                  <div className="ex-container">
                    <img src={group} alt="user" width="60%" height="75%" />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt" }}
                      >
                        The community at the center
                      </span>
                      <br />
                      Enjoy the best of peer-led learning with tons of events
                      while building lifelong relationships
                    </span>
                  </div>
                  <hr />
                  <div className="ex-container">
                    <img src={single} alt="study" width="60%" height="75%" />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Learn by Doing
                      </span>
                      <br />
                      Actionable programs to offset the Pareto Principle; you
                      will execute projects using what you learned
                    </span>
                  </div>

                  <hr />
                </Fade>
              </Col>

              <Col />
            </Row>
          </div>
        </div> */}
          {/* <br />
        <br />
        <br /> */}
          <br />
          <div>
            <h1
              className="quote"
              style={{ textAlign: "center", fontWeight: "Prompt" }}
            >
              Community-led learning is the future
            </h1>
            <h6
              className="acc-cont"
              style={{ textAlign: "center", fontWeight: "Prompt" }}
            >
              Kamb Geeks facilitate a dynamic community to build real-world
              probem solving skills
            </h6>
            <br />
            <br />
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="12" xs="12">
                <Fade right>
                  <div className="ex-container">
                    <img
                      src={numone}
                      alt="number_one"
                      width="60%"
                      height="75%"
                    />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt", color: "white" }}
                      >
                        Build relationship beyond networks
                      </span>
                      <br />
                      Work with motivated peers having fresh out-of-the-box
                      thinking and showing you new ways to do things
                    </span>
                  </div>

                  <hr />
                  <div className="ex-container">
                    <img
                      src={numtwo}
                      alt="number_two"
                      width="60%"
                      height="75%"
                    />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Live events to engage in
                      </span>
                      <br />
                      Participate in live Q&As, debates, hackathons, and other
                      healthy contests to drive home the learning
                    </span>
                  </div>
                  <hr />
                  <div className="ex-container">
                    <img
                      src={numthree}
                      alt="number_three"
                      width="60%"
                      height="75%"
                    />
                    <span className="text" style={{ fontFamily: "Prompt" }}>
                      <span
                        className="a-head ae-head"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Find career opportunities
                      </span>
                      <br />
                      Become a part of an ecosystem where members help access
                      opportunities for jobs and freelance projects
                    </span>
                  </div>

                  <hr />
                </Fade>
              </Col>
              <Col md="6" lg="6" sm="0" xs="0">
                <Bounce>
                  <img
                    src={ace1}
                    alt="global"
                    width="100%"
                    height="100%"
                    className="pic_acc1"
                  />
                </Bounce>
              </Col>
            </Row>
          </div>
          <br />
          <br />
          {/* <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Our Recent Collaboration
          </h1>
          <div className="justify-content-center ">
            <div className="c-card-row justify-content-center ">
              <OwlCarousel
                items={1}
                loop
                nav
                autoplay
                autoplayTimeout={3000}
                className="justify-content-center"
              >
                {this.state.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "80%",
                        paddingLeft: "43%",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div> */}
          <br />
          <div>
            <Footer />
          </div>
        </div>

        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p
                  style={{
                    fontSize: "18px",
                    color: "black",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Claim 10% off!
                </p>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}
