import React from "react";
import "../styles/footer.css";
import logo from "../images/kambg.png";
import Image25 from "../images/facebook.png";
import Image26 from "../images/twitter.png";
import Image27 from "../images/instagram.png";
import you from "../images/youtube.jpg";

function Footer() {
  return (
    <footer>
      <div class="footer-down">
        <h1>
          <b>Trending Post Graduate Programs</b>
        </h1>
        <p>
          Artificial Intelligence Course | Cloud Computing Certification Course
          | Full Stack Web Development Course | PG in Data Science MS in
          Artificial Intelligence | Product Management Certification Course |
          Blockchain Course | Machine Learning Course | Cyber Security Course in
          India | Project Management Certification Course | Cyber Security
          Certification Courne | Lean Six Sigma Certification Course | Data
          Analytics Program | Al and ML Course | Business Analysis Certification
          Course
        </p>
        <h1>
          <b>Trending Master Programs</b>
        </h1>
        <p>
          PMP Plus Certification Training Course | Data Science Certifiation
          Course | Data Analyst Course Masters in Artificial Intelligence |
          Cloud Architect Certification Training Course | DevOps Engineer
          Certification Training Course | Digital Marketing Course | Cyber
          Security Expert Course | MEAN Stack Developer Course Business Analyst
          Course
        </p>
      </div>
      {/* <hr /> */}
      {/* <hr /> */}
      <div class="footer-content">
        <div class="left-section">
          <img src={logo} alt="logo"></img>
        </div>
        {/* <div class="right-section">
          <h6 class="h8color">
            <b>Comparisions</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="">Why Choose Us</a>
            </li>
            <li>
              <a href="">Our Services</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
            <li>
              <a href="">Our Products</a>
            </li>
          </ul>
        </div> */}
        <div class="right-section">
          <h6 class="h8color">
            <b>Services</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="/Skill">Why skills?</a>
            </li>
            <li>
              <a href="/Corporates">Corporates</a>
            </li>
            <li>
              <a href="/technologies">Courses</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
          </ul>
        </div>
        <div class="right-section">
          <h6 class="h8color">
            <b>Courses</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="/technologies">Data Engineering</a>
            </li>
            <li>
              <a href="/technologies">Full Stack Development</a>
            </li>
            <li>
              <a href="/technologies">Mobile Development</a>
            </li>
            <li>
              <a href="/technologies">DevOps Engineer</a>
            </li>
          </ul>
        </div>
        <div class="right-section">
          <h6 class="h8color">
            <b>Courses</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="/technologies">IoT</a>
            </li>
            <li>
              <a href="/technologies">UI/UX Courses</a>
            </li>
            <li>
              <a href="/technologies">Artificial Intelligence</a>
            </li>
            <li>
              <a href="/technologies">Data Science</a>
            </li>
          </ul>
        </div>
        <div class="right-section">
          <h6 class="h8color">
            <b>Resources</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="/ContactUs">ContactUs</a>
            </li>
            {/* <li>
              <a href="/ContactUs" >
                {" "}
                Company
              </a>
            </li> */}
            <li>
              <a href="/ContactUs">Privacy Policy</a>
            </li>
            <li>
              <a href="/ContactUs">Terms of Use</a>
            </li>
          </ul>
        </div>
        <div class="right-section">
          <h6 class="h8color">
            <b>Follow us on</b>
          </h6>
          <ul class="footer-menu">
            <li>
              <a href="https://youtube.com/@KambGeeks?si=3pIwN4dSaN64I_mk">
                Youtube
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/kambgeeks/"> Instagram</a>
            </li>
            <li>
              <a href="https://www.facebook.com/KambGeeks">Facebook</a>
            </li>
            <li>
              <a>Twitter</a>
            </li>
          </ul>
        </div>
      </div>
      <br></br>
      <br></br>
      {/* <hr></hr> */}
      <div class="social-media">
        <h6 class="h8color">
          <b>Follow us on </b>
        </h6>
        <a href="https://www.facebook.com/KambGeeks">
          <img class="footimg" src={Image25} alt="Facebook"></img>
        </a>
        <a href="">
          <img class="footimg" src={Image26} alt="Twitter"></img>
        </a>
        <a href="https://www.instagram.com/kambgeeks/">
          <img class="footimg" src={Image27} alt="Instagram"></img>
        </a>{" "}
        <a href="https://youtube.com/@KambGeeks?si=3pIwN4dSaN64I_mk">
          <img class="footimg" src={you} alt="youtube"></img>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
