import "../styles/navbar.css";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";

function navbar() {
  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img id="logo" src={image1} alt="logo"></img>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a className="nav-link" href="/Skill">
                Why skills&nbsp;?
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Corporates">
                Corporates
              </a>
            </li>
            <div className="courseonly">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="/technologies"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Courses
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <ul class="list-group">
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        <img class="tag" src={image2}></img> &nbsp; Software dev
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Database
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Game development
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mobile development
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Programming languages
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Web Development
                      </a>
                    </li>
                  </ul>
                  <ul class="list-group">
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        <img class="shield" src={image3}></img>&nbsp; Security
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Certification
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Governance,risk,& compliance
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Security architecture & engineering
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Security operations
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Security testing
                      </a>
                    </li>
                  </ul>
                  <ul class="list-group">
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        <img class="brain" src={image4}></img>&nbsp; Machine
                        learning
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Big data
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Business intelligence
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Data visualization
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Databases
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Languages & libraries
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Machine learning
                      </a>
                    </li>
                  </ul>
                  <ul class="list-group">
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        <img class="cloud" src={image5}></img> &nbsp; Cloud
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Cloud architecture & design
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Cloud platforms
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Salesforce CRM
                      </a>
                    </li>
                  </ul>
                  <ul class="list-group">
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        <img class="shield" src={image3}></img>&nbsp; IT Ops
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Client operating systems
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Collaboration platforms
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Configuration management
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Containers
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        IT automation
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Network architecture
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Virtualization
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </div>
            <div className="courseonly1">
              <li class="nav-item dropdown dropdown-mega position-static">
                <a
                  href="/technologies"
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="Outside"
                >
                  Courses
                </a>

                <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                  <div class="mega-content px-md-4">
                    <div class="conatiner-fluid">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                          <div class="logs mb-3">
                            <img class="tag" src={image2}></img>
                            <h5 class="drop-title my-2 mx-2">Software dev</h5>
                          </div>
                          <div class="list-group">
                            <a class="dropdown-item" href="/technologies">
                              Database
                            </a>
                            <a class="dropdown-item" href="/technologies">
                              Game development
                            </a>
                            <a class="dropdown-item" href="/technologies">
                              Mobile development
                            </a>
                            <a class="dropdown-item" href="/technologies">
                              Programming languages
                            </a>
                            <a class="dropdown-item" href="/technologies">
                              Web development
                            </a>
                          </div>
                          <div class="logs mb-3">
                            <img class="shield" src={image3}></img>
                            <h5 class="drop-title my-3 mt-4 mx-2">Security</h5>
                          </div>

                          <div class="list-group">
                            <a class="dropdown-item" href="technologies">
                              Certification
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Governance,risk,& compliance
                            </a>
                            <a class="dropdown-item " href="technologies">
                              Security architecture & engineering
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Security operations
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Security testing
                            </a>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                          <div class="logs mb-2">
                            <img class="brain" src={image4}></img>
                            <h5 class="drop-title mt-2">
                              Data & machine learning
                            </h5>
                          </div>

                          <div class="list-group">
                            <a class="dropdown-item" href="technologies">
                              Big data
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Business intelligence
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Data visualization
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Databases
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Languages & libraries
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Machine learning
                            </a>
                          </div>
                          <div class="logs mb-3">
                            <img class="cloud" src={image5}></img>
                            <h5 class="drop-title my-3 px-2">Cloud</h5>
                          </div>

                          <div class="list-group">
                            <a class="dropdown-item" href="technologies">
                              Cloud architecture & design
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Cloud platforms
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Salesforce CRM
                            </a>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                          <div class="logs mb-3">
                            <img class="setting" src={image6}></img>
                            <h5 class="drop-title my-2 mx-1">IT Ops</h5>
                          </div>

                          <div class="list-group">
                            <a class="dropdown-item" href="technologies">
                              Client operating systems
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Collaboration platforms
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Configuration management
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Containers
                            </a>
                            <a class="dropdown-item" href="technologies">
                              IT automation
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Network architecture
                            </a>
                            <a class="dropdown-item" href="technologies">
                              Virtualization
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            {/* <li className="nav-item">
              <a className="nav-link" href="about">
                Company
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/ContactUs">
                ContactUs&nbsp;
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Register">
                Free Register&nbsp;
              </a>
            </li>
          </ul>
          {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
        </div>
      </div>
    </nav>
    // {/* <nav className="navbar navbar-expand-lg" id="navbar">
    //   <img id="logo" src={image1} alt="logo"></img>
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-bs-toggle="collapse"
    //     data-bs-target="#menu"
    //   >
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="16"
    //       height="16"
    //       fill="currentColor"
    //       class="bi bi-list"
    //       viewBox="0 0 16 16"
    //       style={{
    //         display: "initial",
    //         height: "35px",
    //         width: "35px",
    //         float: "right",
    //         marginRight: "5%",
    //         backgroundColor: "white",
    //       }}
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    //       />
    //     </svg>
    //   </button>
    //   <div className="collapse navbar-collapse" id="menu">
    //     <ul className="navbar-nav ms-auto">
    //       <li className="nav-item">
    //         <a className="nav-link" href="/">
    //           Home
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/Skill">
    //           Why skills&nbsp;?
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/Corporates">
    //           Corporates
    //         </a>
    //       </li>

    //       <li class="nav-item dropdown dropdown-mega position-static">
    //         <a
    //           href="/technologies"
    //           class="nav-link"
    //           id="navbarDropdown"
    //           role="button"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //           data-bs-auto-close="Outside"
    //         >
    //           Courses
    //         </a>

    //         <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
    //           <div class="conatiner-fluid">
    //             <div class="row">
    //               <div className="Solution-content">
    //                 <div class="Solution-box col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
    //                   <div class="logs mb-3">
    //                     <img class="tag" src={image2}></img>
    //                     <h5 class="drop-title my-2 mx-2">Software dev</h5>
    //                   </div>
    //                   <div class="list-group">
    //                     <a class="dropdown-item" href="/technologies">
    //                       Database
    //                     </a>
    //                     <a class="dropdown-item" href="/technologies">
    //                       Game development
    //                     </a>
    //                     <a class="dropdown-item" href="/technologies">
    //                       Mobile development
    //                     </a>
    //                     <a class="dropdown-item" href="/technologies">
    //                       Programming languages
    //                     </a>
    //                     <a class="dropdown-item" href="/technologies">
    //                       Web development
    //                     </a>
    //                   </div>
    //                   <div class="logs mb-3">
    //                     <img class="shield" src={image3}></img>
    //                     <h5 class="drop-title my-3 mt-4 mx-2">Security</h5>
    //                   </div>

    //                   <div class="list-group">
    //                     <a class="dropdown-item" href="technologies">
    //                       Certification
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Governance,risk,& compliance
    //                     </a>
    //                     <a class="dropdown-item " href="technologies">
    //                       Security architecture & engineering
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Security operations
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Security testing
    //                     </a>
    //                   </div>
    //                 </div>
    //                 <div class="Solution-box1 col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
    //                   <div class="logs mb-2">
    //                     <img class="brain" src={image4}></img>
    //                     <h5 class="drop-title mt-2">Data & machine learning</h5>
    //                   </div>

    //                   <div class="list-group">
    //                     <a class="dropdown-item" href="technologies">
    //                       Big data
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Business intelligence
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Data visualization
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Databases
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Languages & libraries
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Machine learning
    //                     </a>
    //                   </div>
    //                   <div class="logs mb-3">
    //                     <img class="cloud" src={image5}></img>
    //                     <h5 class="drop-title my-3 px-2">Cloud</h5>
    //                   </div>

    //                   <div class="list-group">
    //                     <a class="dropdown-item" href="technologies">
    //                       Cloud architecture & design
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Cloud platforms
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Salesforce CRM
    //                     </a>
    //                   </div>
    //                 </div>
    //                 <div class="Solution-box2 col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
    //                   <div class="logs mb-3">
    //                     <img class="setting" src={image6}></img>
    //                     <h5 class="drop-title my-2 mx-1">IT Ops</h5>
    //                   </div>

    //                   <div class="list-group">
    //                     <a class="dropdown-item" href="technologies">
    //                       Client operating systems
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Collaboration platforms
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Configuration management
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Containers
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       IT automation
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Network architecture
    //                     </a>
    //                     <a class="dropdown-item" href="technologies">
    //                       Virtualization
    //                     </a>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </li>

    //       <li className="nav-item">
    //         <a className="nav-link" href="about">
    //           Company
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/ContactUs">
    //           ContactUs&nbsp;
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </nav> */}
  );
}

export default navbar;
